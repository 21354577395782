import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
  ZWrapper,
} from '../ui-components';
import styled from "styled-components";

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 420px;
`

const FOLLOW_ME = "https://www.followmee.com/maps.aspx?key=e0d3537f71efb5a0b9d5ccbf941ba19c&username=betriton&type=2&deviceid=12780264&function=currentfordevice"

const FindMePage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html.split("<hr>");

  return (
    <>
      <SEO
        canonical="/find-me/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>
          <PageTitle>{content.title}</PageTitle>

          <PageHTML dangerouslySetInnerHTML={{ __html: html[2] }} center />

          <ZWrapper>
          <Frame name="fmeeframe" 
            frameborder="0" 
            scrolling="no"
            marginheight="0"
            marginwidth="0" 
            src={FOLLOW_ME}
          />
          </ZWrapper>

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}



export default FindMePage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/find-me.md/"}) {
    frontmatter {
      title
    }
    html
  }
}  
`;
